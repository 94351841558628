











































































































































































































































.scrollDownBtn {
  background-color: white;
  position: fixed !important;
  right: 30px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  bottom: 100px;
}

.root {
  background-color: #f6f6f6;
}
.scroller {
  scroll-behavior: smooth;
  padding: 10px 9%;
}
