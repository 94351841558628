
























.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
