






















































































.message-row {
  margin-bottom: 3px;
  text-align: left;

  &.own {
    text-align: right;

    .content {
      background-color: #dcf8c6;
    }
  }
}

.username {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.content {
  position: relative;
  text-align: left;
  background: #fffaf5;
  padding: 8px 8px 12px 8px;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  max-width: 50%;

  @media (max-width: 480px) {
    max-width: 60%;
  }

  .showUser.own &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -12px;
    top: 0;
    bottom: auto;
    border: 12px solid;
    border-color: #dcf8c6 transparent transparent transparent;
  }

  .showUser &:before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: -12px;
    right: auto;
    top: 0;
    bottom: auto;
    border: 12px solid;
    border-color: white transparent transparent transparent;
  }
}

.timePlaceholder {
  display: inline-block;
  width: 110px;
}

.time {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 8px;
  bottom: 0;
  white-space: nowrap;
}
