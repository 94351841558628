






























































































.autoCompleted .v-icon {
  transform: rotate(0deg) !important;
}
