






































































































































































































































































































































.custom-scroll-area {
  height: 335px;
  overflow-y: auto;
}
.text {
  color: #8898aa;
  font-size: 14px;
}

.itemFieldName {
  font-weight: 600;
  color: #525f7f;
  font-size: 14px;
}
