












































































































































































































































































































































































































































































































































#app .chatTopButton {
  color: white !important;
}

.scrollable {
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-y: auto;
}

.dialog-form {
  padding: 10px 23px;
}

.dialog-content {
  height: 70vh;
  overflow-y: scroll;
}

.dialog-header {
  font-size: 20px;
}
